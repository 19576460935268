#AppMessages {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100vw;
  text-align: center;
  z-index: 10000;

  .MuiAlert-root {
    margin: 0 auto;
    max-width: 800px;
  }
}
