#LoginPage {
  min-height: 80vh;

  h6 {
    text-align: center;
  }

  .InitLoader {
    margin: 25px;
  }
}
