@import "variables";

#root {
  .MuiStepIcon-root {
    &.Mui-active {
      color: $stepRed;
    }
    &.Mui-completed {
      color: $stepRed;
    }
  }

  .MuiPaper-root {
    padding-left: $PaperSpacing;
    padding-right: $PaperSpacing;

    .MuiToolbar-root {
      padding-left: 0;
      padding-right: 0;
    }

    &.selected {
      border: 2px solid $secondary;
    }
  }

  .MuiTable-root {
    .MuiTableRow-root {
      th {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 13px;
      }
      td {
        line-height: 20px;
      }
      .MuiTableCell-root {
        &.MuiTableCell-alignCenter {
          .MuiAvatar-root {
            margin: auto;
          }
        }
        &.noPaddingY {
          padding-top: 0;
          padding-bottom: 0;
        }
        &.MuiTableCell-head,
        &.MuiTableCell-body {
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .MuiInputLabel-asterisk.MuiFormLabel-asterisk {
    color: red;
  }

  .MuiCheckbox-root {
    &.small {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  // margin bottom on pages or papers
  .marginBottom {
    margin-bottom: $PaperSpacing;
    .readOnly {
      .action {
        display: none;
      }
    }
  }

  // adjust Label on inline editing selects
  .MuiInputLabel-root.MuiFormLabel-root.inlineMode {
    transform: translate(12px, 5px) scale(0.75);
    font-size: 0.875rem;
  }
  // adjust background color for inline editing fields
  .MuiFilledInput-root.MuiInputBase-root {
    &.MuiInputBase-formControl {
      background-color: rgba(0, 0, 0, 0.03);
      font-size: 0.875rem;
    }
    .MuiFilledInput-input {
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(0, 0, 0, 1);
      }
    }
  }
  // adjust autocomplete field without label
  .noLabel {
    .MuiFilledInput-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
      padding-top: 15px;
    }
  }
  // adjust readonly color on sender select
  .MuiSelect-select.MuiInputBase-readOnly {
    .MuiBox-root {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  // custom outlined field
  .MuiFormGroup-root {
    &.bordered {
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 10px;
    }

    &.withPaddings {
      padding-top: 8px;
    }

    &.filled {
      background-color: rgba(0, 0, 0, 0.03);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);

      &.withPaddings {
        padding-top: 11px;
        padding-left: 8px;
      }
    }
  }
  .MuiTextField-root {
    &.qty {
      input {
        text-align: center;
      }
    }
  }
  // add some space between chips in a chip list
  .ChipList {
    .MuiChip-root {
      margin-right: 5px;
    }
  }
  .KeywordChip {
    margin-right: 5px;
  }

  // disable arrows on number fields
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // colorized chips
  .MuiChip-root {
    font-size: $font-size;
    .MuiChip-label {
      font-size: $font-size;
    }
    &.MuiChip-colorDefault {
      &.KeywordChip {
        max-width: 140px;
      }

      &.WinChip {
        max-width: 200px;
      }

      &.red {
        .MuiSvgIcon-root {
          color: red;
        }
      }

      &.yellow {
        .MuiSvgIcon-root {
          color: yellow;
        }
      }

      &.green {
        .MuiSvgIcon-root {
          color: green;
        }
      }
    }
  }

  th.MuiTableCell-root.MuiTableCell-head {
    position: relative;

    .SortBtn {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 7px;
      cursor: pointer;
    }
  }
}
