@import "src/ui/theme/variables";

#SideBar {
  width: $SideBarWidth;

  .MuiToolbar-root {
    padding: 0;

    .MuiTypography-root {
      color: $secondary;
      font-weight: 900;
      font-size: 24px;
    }
  }

  .MuiPaper-root {
    width: $SideBarWidth;
    background-color: $bg;
    overflow: hidden;
    border-right: none;

    nav {
      .collapseOpen,
      .MuiCollapse-entered {
        background-color: #ffffff;
      }
    }

    .MuiListItemIcon-root {
      padding-left: 0;
      min-width: 68px;
    }
  }

  .SubEntry {
    padding-left: 85px;
  }

  &.SideBarOpen {
    width: $SideBarWidthOpen;

    .MuiPaper-root {
      width: $SideBarWidthOpen;
    }
  }
}
