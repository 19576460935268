@import "src/ui/theme/variables";
@import "src/ui/theme/mui";

body.RAAS {
  font-size: $font-size;
  color: $text;
  background-color: $bg;
  a {
    color: $grey;
    &:active {
      color: $blue;
    }
  }

  .siteLogo {
    color: $red;
  }
  .menuIcon {
    font-size: 32px;
    color: $grey;
    &.selected {
      color: $blue;
    }
  }

  .eventStatus,
  .statusIcon {
    &.red {
      color: $status_red;
    }
    &.blue {
      color: $status_blue;
    }
    &.orange {
      color: $status_orange;
    }
    &.green {
      color: $status_green;
    }
    &.yellow {
      color: $status_yellow;
    }
    &.grey {
      color: transparent;
      border: 1px solid $status_grey;
      border-radius: 50%;
    }
  }
}
