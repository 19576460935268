.ListTable {
  &.MuiTableContainer-root {
    position: relative;

    .MuiBackdrop-root {
      position: absolute;
    }
  }

  th.MuiTableCell-root.MuiTableCell-head {
    white-space: nowrap;
  }

  tbody {
    tr:hover {
      background-color: lightgray;
    }

    tr.selected {
      background-color: lightblue;
    }
  }
}
