@import "src/ui/theme/variables";

.WinnersList {
  &.MuiTableContainer-root {
    position: relative;

    .MuiBackdrop-root {
      position: absolute;
    }
  }

  th.MuiTableCell-root.MuiTableCell-head {
    white-space: nowrap;
  }
}
