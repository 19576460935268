@import "src/ui/theme/variables";

#AddButtons {
  .MuiPaper-root {
    cursor: pointer;
    background-color: $blue;
    color: $white;
    .MuiSvgIcon-root {
      font-size: 60px;
    }
  }
}