$primary: #1f3144;
$secondary: #e13239;

$bg: #f6f6f6;

$SideBarWidth: 104px;
$SideBarWidthOpen: 319px;

$PaperSpacing: 20px;

$main: #000000;
$text: #757575;
$blue: #1e3144;
$red: #e72b37;
$stepRed: #e50040;
$green: green; //#42bd41;
$chip: #f0f0f0;
$active: #1e3144;
$grey: #8c8c8c;
$lightGrey: #d8d8d8;
$white: #ffffff;
$orange: #ffa229;
$yellow: #ffff00;

$status_red: $red; // überfaellig
$status_green: $green; //aktiv
$status_grey: $grey; //inaktiv
$status_blue: lighten($blue, 20%); //abgeschlossen
$status_orange: $orange; //Bearbeitung
$status_yellow: $yellow;

$font-size: 0.875rem;
