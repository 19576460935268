#SponsorsFilter {
  float: left;
  padding-top: 10px;
  padding-left: 0;

  .searchBox {
    float: left;
  }
  .separator {
    margin: 5px;
    line-height: 36px;
  }
  .MuiTextField-root.dateField {
    input {
      width: 90px;
    }
  }
}
